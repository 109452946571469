import React from 'react'

import Layout from '../layouts'
import styled from 'styled-components';

const Cards = styled.div`

  @media only screen and (min-width: 500px) {
    grid-template-columns: 1fr;
  }

  @media only screen and (min-width: 850px) {
    grid-template-columns: 1fr 1fr ;
  }

  @media only screen and (min-width: 1140px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const LetterSection = styled.p`
`;

const Letter = styled.span`
  font-size: 8em;
  line-height: 0.8;
`

export default ({ data }) => (
  <Layout>
    <h1>Att vara konsult</h1>
    <p>
      Välkommen till Evolve-familjen. Du är nu en av oss just för vi anser att du har vad som krävs för att vara en Evolvare. Förutom att du har hög tekniskt kompetens så har du garanterat också en härlig personlighet och ett inre driv. Du är genuint omtänksam som person samt bryr dig om människor i din omgivning. Möjligtvis har du många år bakom dig som konsult alternativt kommer du direkt från ett produktbolag och är då helt ny i rollen som konsult. Att vara konsult kan skilja sig en hel del från att vara anställd på t ex ett produktbolag. Oavsett vilken erfarenhet du har med dig delar vi här med oss av några värdefulla tips på hur du framgångsrikt lyckas extra bra i konsultrollen hos oss på Evolve:
    </p>
    <LetterSection>
      <Letter>E</Letter>volve är du, och du är Evolve! Som konsult representerar du i alla lägen Detta låter kanske självklart för dig men eftersom att vi marknadsför oss som, ”the best of the best” betyder detta att varje enskild individ är Evolves varumärke. Här kommer din sociala förmåga att bli avgörande för att kunna etablera långsiktiga och framgångsrika relationer till såväl kunder som kollegor. Genom ditt uppdrag ute hos kund samt ditt agerande och engagemang i teamet är du dels representant för dig själv (ditt eget varumärke) men även för Evolves varumärke och genom att vårda dina kontakter och relationer kan du uppnå många positiva effekter som t.ex. förlängning av ditt uppdrag och nya kunder.
    </LetterSection>
    <LetterSection>
      <Letter>V</Letter>isa framåtanda! Som konsult hos oss besitter du ett inre driv och hög arbetsmoral. Du har ingen chef som kontinuerligt pushar dig framåt utan du har fullt eget ansvar. Som konsult tycker du därför om att självständigt leda dig själv och ditt arbete framåt samtidigt är du alltid uppbackad av 50 fantastiska kollegor. Du vågar ta för dig och en stor del av arbetet som konsult handlar om att identifiera utmaningar samt att hitta lösningar som tar dig och teamet framåt. Som konsult måste du ibland finna dig i och tycka om tvära kast och nya förutsättningar, det vill säga att vara förändringsbenägen samtidigt som det är viktigt att tillägga att vi på Evolve alltid gör affärer med fokus på långsiktighet. Du kommer dock att byta uppdrag och miljöer oftare än som anställd på t ex ett produktbolag. Där av måste du tycka om att träffa nya människor samt tycka om att snabb komma in i nya kontexter och team. Som konsult måste du vara beredd på att ständigt vilja förändras och utvecklas. Det är viktigt att fortsätta våga utvecklas i takt med tekniken.
    </LetterSection>
    <LetterSection>
      <Letter>O</Letter>msätta Evolves Equal-DNA. På Evolve jobbar vi kontinuerligt för att alla ska känna sig inkluderade. Ingen ska känna sig exkluderad eller på annat sätt diskriminerad pga kön, etnicitet, sexuell läggning, trosuppfattning, funktionsvariation eller ålder. Detta önskar vi även att vi alla omsätter ute hos kund såväl som i vår omgivning. Genom vårt DNA tror vi på att jobba för en öppen kultur där tröskeln för att våga ta upp och ha viktiga samtal ska vara låg. Vi vill motverka en tystnadskultur genom att ha en prestigelös stämning oavsett om det gäller en fråga av teknisk natur eller ett samtal kring eventuellt kränkande eller diskriminerande beteenden. I vår verksamhet tar vi avstånd från alla tendenser till kränkningar. Miljön skall vara trygg och ingen skall känna sig utsatt för kränkning av något slag.
Känner du t ex att något inte stämmer, känns fel eller du har bekymmer på hemmaplan så uppmanar vi alla att lyfta detta omgående. Livet går upp och ner och vi finns här för dig som stöttning i alla lägen. Önskar du ha samtal i förtroende får du mer än gärna lyfta dina funderingar och tankar under dina heart-to-heart-samtal med någon av våra två kontaktpersoner med personalansvar, Natasha Diba alternativt Amir Jorshari.
    </LetterSection>
    <LetterSection>
      <Letter>L</Letter>yssna och kommunicera! Förmågan att kommunicera och lyssna såväl på kollegor internt som kunder och teammedlemmar anser vi är en avgörande framgångsfaktor och som konsult hos oss måste du vara bra på att förmedla budskap både i tal och skrift samt att kunna anpassa ditt budskap i olika situationer och till olika målgrupper. I ditt konsultuppdrag så är det viktigt att vara lyhörd på vad som händer inom bolaget, inte bara i det team du sitter i utan även vad som händer runt omkring. Det kan vara allt från att något team behöver rampa upp med fler utvecklare till att veta när det är dags att börja lyfta ut konsulter vid en eventuell nedskärning.
Självklart har vi här även stor förståelse och respekt för att alla människor inte är lika bekväma i all typ av kommunikation och att det inte faller helt naturligt. Evolve erbjuder självklart stöd i denna process så vänd dig till en kollega, din coach eller kontaktperson om du t ex har någon fundering kring ett viktigt mail eller någon tanke du vill lyfta hos kund så hjälps vi åt. Kom ihåg att vi är här för varandra. Och sist men inte minst, kommunikation skapar relationer. På ditt uppdrag kan vi hitta framtida Evolvare.
    </LetterSection>
    <LetterSection>
      <Letter>V</Letter>etgirighet. Ju mer du delar med dig av kunskap på ditt uppdrag desto mer uppskattad blir du som teammedlem och vips så är du kuggen som får maskineriet att snurra.  Som konsult i nytt uppdrag är det oftast en kort uppstartssträcka och du förväntas leverera resultat relativt tidigt. Du kommer in för att tillföra värde samt förbättra saker och detta ställer krav på att du är vetgirig och snabbt kan sätta dig in i nya saker. Se till att komma väl förberedd till nya uppdraget både vad gäller teknik/domän/kunskap om kunden så att du snabbare kommer igång och kan bidra med värde till ditt nya team. Ett tips är att försöka utvärdera kommande uppdrag utifrån dina tidigare erfarenheter: Vad är nytt i uppdraget? Vad kommer du att kunna bidra med? Ta gärna hjälp av Evolvekollegor, det finns garanterat någon som har tidigare branscherfarenhet från det uppdrag du är på väg in i. Vi vill även uppmuntra dig till att bolla alla frågor gällande din kompetensutveckling direkt med vår Coach!
    </LetterSection>
    <LetterSection>
      <Letter>E</Letter>ngagemang. Vi på Evolve gör alltid det lilla extra för våra kunder och kollegor. Var inte rädd för att synas och höras och var engagerad genom att komma med idéer och var delaktig i diskussioner. Detta gäller internt på Evolve så väl som hos kund. Med engagemang och entreprenörskap kommer även en god problemlösningsförmåga. Våga ventilera och lyft dina tankar och funderingar tidigt med oss. Som konsult kan det löpande uppkomma behov av att diskutera tankar och funderingar som dyker upp i ditt uppdrag hos kund, saker som händer i ditt privatliv eller funderingar som du har gällande din roll på Evolve. Tveka inte att lyfta dessa med oss. Vi har en samlad erfarenhet från många olika typer av kunder och konsultuppdrag och därigenom även erfarenhet av vilka utmaningar du kan stöta på i din konsultroll. Du bör även våga stå upp för vad du tycker och säga ifrån om du anser kunden är på väg åt fel håll. Känner du dig inte bekväm i att göra detta själv, ta hjälp av oss så lyfter vi problematiken tillsammans. Du är aldrig ensam i dina utmaningar utan har ett helt bolag av 50 kollegor som backar upp och stöttar dig!
    </LetterSection>
  </Layout>
)
